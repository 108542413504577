@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

html {
  /* 1rem = 16px */
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  /* don't add border and padding to calculate element width */
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Figtree", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

.break {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.table-header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  color: #a5a5a5;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.table-even {
  background-color: white;
}

.table-odd {
  background-color: #FAFAFA !important;
}

.cell-center {
  display: flex;
  justify-content: center !important;
}


